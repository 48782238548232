import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '../Popover';
import TheatersOutlinedIcon from '@material-ui/icons/TheatersOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import clsx from 'clsx';
import Tutorial from '../VideoGenerator/components/Tutorial';
import Badge from '@material-ui/core/Badge';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { useRouter } from 'next/router';

const useStyles = makeStyles(theme => ({
  menuItem: {
    padding: '6px 16px',
    display: 'flex',
    justifyContent: 'flex-start',
    // textTransform: 'capitalize',
    fontSize: 14,
    // fontWeight: 300,
    lineHeight: '19px',
    fontFamily: 'Open sans, sans-serif',
    color: '#414042',
    // letterSpacing: '1.4px',
    '&:hover': {
      backgroundColor: '#f9f9f9'
    },
    marginBottom: 4,
    // paddingTop: 0,
    // paddingBottom: 0
    '& span': {
      marginLeft: 10
    },
    '& .wistia_embed': {
      marginLeft: 0
    },
    position: 'relative',
    '& .MuiBadge-root': {
      position: 'absolute',
      top: 10,
      right: 20,
      '& .MuiBadge-colorPrimary': {
        background: '#E62E2D'
      }
    }
  },
  userMenuButton: {
    padding: '0',
    borderRadius: '50%',
    minWidth: 0,
    marginLeft: 10,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& svg': {
      left: 'unset',
      right: 14,
      top: 18
    },
    '&.white': {
      color: '#fff'
    },
    '@media only screen and (max-width: 960px)': {
      marginLeft: 0
    }
  },
  list: {
    // paddingBottom: 20,
    '@media only screen and (max-width: 768px)': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  paper: {
    boxShadow: '0px 3px 18px #00000029',
    borderRadius: 12,
    padding: 0
  },
  tutorial: {
    color: '#414042!important',
    textDecoration: 'none!important',
    marginLeft: '0px!important'
  }
}));

const HelpMenu = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const router = useRouter();

  const handleClick = event => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavTutorial = () => {
    setOpen(false);
    window.open('https://support.woxo.tech/en', '_blank');
  };

  const handleGoToRoadmap = () => {
    setOpen(false);
    window.open('https://www.notion.so/WOXO-s-Roadmap-8804e18c43a04f8ca29a141621238f24', '_blank');
  };

  return (
    <div className="user-menu">
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        content={
          <Paper className={classes.paper}>
            <MenuList id="menu-list-grow" className={classes.list}>
              <MenuItem onClick={handleNavTutorial} className={classes.menuItem}>
                <LaunchIcon />
                <span>Tutorials</span>
              </MenuItem>
              <MenuItem onClick={handleGoToRoadmap} className={classes.menuItem}>
                <MapOutlinedIcon />
                <span>Roadmap</span>
                <Badge color="primary" badgeContent="New"></Badge>
              </MenuItem>
              {router.pathname !== '/browse/[id]' && (
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                  <TheatersOutlinedIcon />
                  <span>
                    <Tutorial text="Watch demo" className={classes.tutorial} />
                  </span>
                </MenuItem>
              )}
            </MenuList>
          </Paper>
        }
      >
        <Button
          onClick={handleClick}
          color="inherit"
          className={clsx(classes.userMenuButton, 'help-btn')}
        >
          <HelpOutlineIcon />
        </Button>
      </Popover>
    </div>
  );
};

export default HelpMenu;
