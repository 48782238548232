import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Navbar from '../../components/MainAppBar';
import Footer from '../../components/Footer';
import SignUp from '../../components/SignUp';
import LogIn from '../../components/LogIn';
import ErrorAlertBoundary from '../../components/ErrorAlertBoundary';
import { Toaster } from 'react-hot-toast';

const useStyles = makeStyles(theme => ({
  content: {},
  container: {
    backgroundColor: '#f2f2f2'
  },
  footer: {}
}));

const Main = ({
  children,
  forwardRef,
  lang,
  navbarContent,
  endNavbarContent,
  navbarClassName,
  containerClassName,
  showLanguageMenu,
  showSignUp,
  showLogin,
  signUpLabel,
  signUpAction,
  showFooter,
  showUserMenu,
  showNotificationPanel,
  showTutorials,
  showVideoPlans,
  audience,
  showSmallFooter,
  showLinks
}) => {
  const classes = useStyles();
  const signUpRef = React.useRef(null);
  const loginRef = React.useRef(null);
  const signUpFn = React.useRef(null);

  React.useImperativeHandle(forwardRef, () => ({
    showSignUp: (fn, config) => {
      signUpFn.current = fn || null;
      signUpRef.current.show(signUpFn.current);
    },
    showLogin: () => {
      signUpFn.current = null;
      loginRef.current.show();
    }
  }));

  const handleLogin = () => {
    signUpRef.current.hide();
    loginRef.current.show(signUpFn.current);
  };

  const handleSignUp = () => {
    loginRef.current.hide();
    signUpRef.current.show(signUpFn.current);
  };

  const handleShowSignUp = () => {
    signUpFn.current = null;
    const action = signUpAction();
    if (action === 'show.popup') signUpRef.current.show();
  };

  return (
    <ErrorAlertBoundary>
      <Box className={clsx(classes.container, containerClassName)}>
        <Toaster position="top-right" reverseOrder={false} toastOptions={{ duration: 6000 }} />
        <Navbar
          onSignUp={handleShowSignUp}
          onLogin={handleLogin}
          lang={lang}
          className={navbarClassName || ''}
          showSignUp={showSignUp}
          showLogin={showLogin}
          showLanguageMenu={showLanguageMenu}
          signUpLabel={signUpLabel}
          showUserMenu={showUserMenu}
          showNotificationPanel={showNotificationPanel}
          showTutorials={showTutorials}
          showVideoPlans={showVideoPlans}
          endContent={endNavbarContent}
          showLinks={showLinks}
        >
          {navbarContent}
        </Navbar>
        <Box className={classes.content} id="woxo-main-container">
          {children}
        </Box>
        {showFooter && <Footer className={classes.footer} showSmallFooter={showSmallFooter} />}
        <SignUp forwardRef={signUpRef} onLogin={handleLogin} audience={audience} />
        <LogIn forwardRef={loginRef} onSignUp={handleSignUp} />
      </Box>
    </ErrorAlertBoundary>
  );
};

Main.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showFooter: PropTypes.bool,
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.any)]),
  lang: PropTypes.oneOf(['', 'pt', 'es']),
  navbarContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  endNavbarContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  navbarClassName: PropTypes.string,
  showSignUp: PropTypes.bool,
  showLogin: PropTypes.bool,
  showLanguageMenu: PropTypes.bool,
  showUserMenu: PropTypes.bool,
  signUpLabel: PropTypes.string,
  signUpAction: PropTypes.func,
  containerClassName: PropTypes.string,
  showNotificationPanel: PropTypes.bool,
  showTutorials: PropTypes.bool,
  showVideoPlans: PropTypes.bool,
  audience: PropTypes.string,
  showSmallFooter: PropTypes.bool,
  showLinks: PropTypes.bool
};

Main.defaultProps = {
  children: null,
  forwardRef: () => {},
  lang: '',
  navbarContent: null,
  endNavbarContent: null,
  navbarClassName: null,
  showLanguageMenu: true,
  showSignUp: true,
  showLogin: true,
  signUpLabel: null,
  showFooter: true,
  showUserMenu: true,
  signUpAction: () => 'show.popup',
  containerClassName: null,
  showNotificationPanel: true,
  showTutorials: true,
  showVideoPlans: false,
  audience: 'widget',
  showSmallFooter: false,
  showLinks: true
};

export default Main;
